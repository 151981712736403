import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

gsap.to(".introAnim", {
  height: "0vh",
  duration: 3,
  ease: "Expo.easeInOut",
});

document.getElementById("roomNavLink").addEventListener("mouseenter", () => {
  document.getElementById("sub_rooms").style.visibility = "visible";
  gsap.to(".sub_room_link", {
    opacity: 1,
    stagger: 0.15,
    ease: "Expo.easeIn",
  });
});

document.getElementById("roomNavLink").addEventListener("mouseleave", () => {
  gsap.to(".sub_room_link", {
    opacity: 0,
  });

  document.getElementById("sub_rooms").style.visibility = "hidden";
});

function disableScroll() {
  // To get the scroll position of current webpage
  let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
  let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
  // if scroll happens, set it to the previous value
  window.onscroll = function () {
    window.scrollTo(LeftScroll, TopScroll);
  };
}

function enableScroll() {
  window.onscroll = function () { };
}

const svgHover = (svgId, hoverEl) => {
  $(document).on("mouseenter", svgId, function () {
    document.getElementById(hoverEl).style.opacity = 1;
  });
  $(document).on("mouseleave", svgId, function () {
    document.getElementById(hoverEl).style.opacity = 0;
  });
};

svgHover("#headerRoomSvg", "roomHover");
svgHover("#headerLocationSvg", "locationHover");
svgHover("#openMenu", "menuHover");

//  text hover Animation end
// ------------------------------------------------------------------------

// ------------------------------------------------------------------------
// svg animaations start

let svgStrokeAnim = (svgId, duration) => {
  $(document).on("mouseenter", svgId, function () {
    let curSvg = $(svgId).drawsvg({
      duration: duration,
      easing: "swing",
    });
    curSvg.drawsvg("animate");
  });
};

svgStrokeAnim("#headerRoomSvg", 500);
svgStrokeAnim("#headerLocationSvg", 500);

let svgStrokeAnimArrow = () => {
  $(document).on("mouseenter", ".menuEmail", function () {
    let arrowRightSvg = $(".placeholderEmail").drawsvg({
      duration: 1000,
      easing: "swing",
    });
    arrowRightSvg.drawsvg("animate");
  });
};
svgStrokeAnimArrow();
// ------------------------------------------------------------------------

// ------------------------------------------------------------------------
// menuContainer start

var t1 = new gsap.timeline({});

t1.to(".nav-container", 0.8, {
  left: 0,
  ease: "Expo.easeInOut",
});

t1.staggerFrom(
  ".navMenuStagger",
  0.8,
  {
    y: 100,
    opacity: 0,
    ease: "Expo.easeOut",
  },
  "0.1",
  "-=0.4"
);
t1.staggerFrom(
  ".navMenuIcon",
  0.8,
  {
    y: 100,
    opacity: 0,
    ease: "Expo.easeOut",
  },
  "0.1",
  "-=0.4"
);

t1.reverse();

function ifChecked(obj) {
  if ($(".openMenu").is(":checked")) {
    if ($(".roomCheckBox").is(":checked")) {
      document.getElementById("roomCheckBox").checked = false;
      t4.reversed(!t4.reversed());
    }
    if ($(".locationCheckBox").is(":checked")) {
      document.getElementById("locationCheckBox").checked = false;
      t5.reversed(!t5.reversed());
    }
    disableScroll();
    t1.reversed(!t1.reversed());
  } else {
    enableScroll();
    t1.reversed(!t1.reversed());
    gsap.to(".headerBgLayer", {
      duration: 0.8,
      left: "-100%",
      ease: "sine.out",
    });
  }
}

document.getElementById("check").onchange = function () {
  ifChecked(this);
};

// menuContainer end
// --------------------------------------------------------------------------

// ------------------------------------------------------------------------
// Modals start

// ***************************************
// Rooms modal start

var t4 = new gsap.timeline({});

t4.to(".roomsModal", 0.3, {
  left: "6.65vw",
  ease: "Expo.easeInOut",
});

t4.staggerFrom(
  ".roomStagger",
  0.6,
  {
    y: 100,
    opacity: 0,
    ease: "Expo.easeOut",
  },
  "0.1",
  "-=0.4"
);

t4.reverse();

function ifChecked4(obj) {
  if ($(obj).is(":checked")) {
    gsap.to(".headerBgLayer", {
      duration: 0.8,
      left: "0",
      ease: "sine.out",
    });
    if ($(".openMenu").is(":checked")) {
      t1.reversed(!t1.reversed());
      document.getElementById("check").checked = false;
    }
    if ($(".locationCheckBox").is(":checked")) {
      t5.reversed(!t5.reversed());
      document.getElementById("locationCheckBox").checked = false;
    }
    disableScroll();
    t4.reversed(!t4.reversed());
  } else {
    gsap.to(".headerBgLayer", {
      duration: 0.8,
      left: "-100%",
      ease: "sine.out",
    });
    enableScroll();
    t4.reversed(!t4.reversed());
  }
}

document.getElementById("roomCheckBox").onchange = function () {
  ifChecked4(this);
};

// Rooms modal end
// ***************************************

// ***************************************
// location modal start

var t5 = new gsap.timeline({});

t5.to(".locationModal", 0.3, {
  left: "6.65vw",
  ease: "Expo.easeInOut",
});

t5.staggerFrom(
  ".locationStagger",
  0.6,
  {
    y: 100,
    opacity: 0,
    ease: "Expo.easeOut",
  },
  "0.1",
  "-=0.4"
);

t5.reverse();

function ifChecked5(obj) {
  if ($(obj).is(":checked")) {
    gsap.to(".headerBgLayer", {
      duration: 0.8,
      left: "0",
      ease: "sine.out",
    });

    if ($(".openMenu").is(":checked")) {
      t1.reversed(!t1.reversed());
      document.getElementById("check").checked = false;
    }
    if ($(".roomCheckBox").is(":checked")) {
      t4.reversed(!t4.reversed());
      document.getElementById("roomCheckBox").checked = false;
    }
    disableScroll();
    t5.reversed(!t5.reversed());
  } else {
    gsap.to(".headerBgLayer", {
      duration: 0.8,
      left: "-100%",
      ease: "sine.out",
    });
    enableScroll();
    t5.reversed(!t5.reversed());
  }
}

document.getElementById("locationCheckBox").onchange = function () {
  ifChecked5(this);
};

// location modal end
// ***************************************

// Modals  end
// ------------------------------------------------------------------------



var tmobileNav = new gsap.timeline({});
  

tmobileNav.to(".mobileNav", {
  duration: 0.5,
  left: "-0%",
  ease: "sine.out",
});

tmobileNav.to(".open_mobile_nav", {
  duration: .2,
  opacity: 0,
  ease: "Expo.easeInOut"
});


tmobileNav.staggerFrom(
  ".mobile_nav_stagger",
  1,
  {
    y: 25,
    opacity: 0,
    ease: "Expo.easeInOut",
  },
  "0.1",
  "-=0.1"
);

tmobileNav.to(".close_mobileNav", {
  duration: .3,
  opacity: 1,
  ease: "Expo.easeInOut"
});


tmobileNav.reverse();




document.getElementById("open_mobile_nav").addEventListener("click", () => {

  tmobileNav.reversed(!tmobileNav.reversed());
  disableScroll();

});


document.getElementById("close_mobileNav").addEventListener("click", () => {
  tmobileNav.reversed(!tmobileNav.reversed());
  enableScroll();

});


